import React from 'react';
import { string } from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import { flow, split } from 'lodash/fp';

export default compose(
  setPropTypes({
    src: string.isRequired,
    alt: string,
    className: string,
  }),
  setDisplayName(__filename),
)(({ src, alt, className = '' }) => (
  <img
    src={src}
    className={className}
    srcSet={`${flow(split('.'), ([name, extension]) => `${name}@2x.${extension}`)(src)} 2x`}
    alt={alt}
  />
));
