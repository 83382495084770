import { keys } from 'lodash/fp';
import getText from 'relient/get-text';
import getOptions from 'relient/get-options';

export const LOGOUT = 'LOGOUT';
export const ACCOUNT_SETTING = 'ACCOUNT_SETTING';

const textMap = {
  [ACCOUNT_SETTING]: 'accountSetting',
  [LOGOUT]: 'logout',
};

export const accountActions = keys(textMap);
export const getAccountAction = getText(textMap);
export const accountActionOptions = getOptions(textMap);
