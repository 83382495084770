exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Zd57{background:#000;color:#fff;padding:40px 0}._3Qx4R{height:250px;color:#cacaca;padding-top:50px}._1UCQa{color:#f2f2f2;cursor:pointer;margin-top:8px;font-size:14px}._1AfCi{display:-ms-flexbox;display:flex;padding-top:10px}.vxET9{margin-left:100px;font-size:18px}.vxET9 a{display:block;text-align:left}.vxET9 a,.vxET9 h6{color:#fff;font-size:18px}.vxET9 h6{padding-left:12px}._2wDvl{font-size:14px;color:#f2f2f2}", ""]);

// exports
exports.locals = {
	"Root": "_1Zd57",
	"Navigator": "_3Qx4R",
	"Fee": "_1UCQa",
	"NavigatorContainer": "_1AfCi",
	"NavigatorItem": "vxET9",
	"Copyright": "_2wDvl"
};