import React from 'react';
import { node, string, object, shape, bool, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import { compose, setDisplayName, withHandlers, setPropTypes } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { frontendLogout as logoutAction } from 'shared/actions/auth';
import { update as updateAccountAction } from 'shared/actions/account';
import { push as pushAction } from 'relient/actions/history';
import motion from 'antd/lib/style/core/motion.less';
import classNames from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import layout from './layout_.scss';
// eslint-disable-next-line css-modules/no-unused-class
import message from '../message_.scss';
import s from './layout.scss';

import Footer from './footer';
import Header from './header';
import SubHeader from './sub-header';
import selector from './layout-selector';

export default compose(
  setPropTypes({
    children: node,
    className: string,
    style: object,
    subHeader: arrayOf(shape({
      name: string.isRequired,
      to: string.isRequired,
      active: bool,
    })),
  }),
  connect(selector, {
    logout: logoutAction,
    push: pushAction,
    updateAccount: updateAccountAction,
  }),
  withHandlers({
    logout: ({ logout, push }) => () => {
      logout();
      push('/auth/login');
    },
  }),
  withStyles(layout, motion, message, s),
  setDisplayName(__filename),
)(({
  children,
  className,
  currentAccount,
  currentFeature,
  logout,
  push,
  subHeader,
  style,
  language,
  showLanguageDropdown,
  updateAccount,
  middleLayer,
}) => (
  <div>
    <Header
      currentAccount={currentAccount}
      currentFeature={currentFeature}
      language={language}
      logout={logout}
      push={push}
      updateAccount={updateAccount}
      showLanguageDropdown={showLanguageDropdown}
    />
    {middleLayer && (
      <div>{middleLayer}</div>
    )}
    {subHeader && (
      <SubHeader data={subHeader} />
    )}
    <div className={classNames(className, s.Content)} style={style}>
      {children}
    </div>
    <Footer />
  </div>
));
