exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._16jK9{background:#f2f2f2;padding-bottom:14px;padding-top:18px}._3wrr5{background:#fff;padding:0 28px}._3wrr5,.KR8XP{display:-ms-flexbox;display:flex}.KR8XP{color:#000;font-size:20px;height:60px;margin-right:95px;-ms-flex-align:center;align-items:center}.KR8XP:hover{text-decoration:none}._6aj_U{color:#fbb03b}", ""]);

// exports
exports.locals = {
	"Root": "_16jK9",
	"Content": "_3wrr5",
	"Link": "KR8XP",
	"Active": "_6aj_U"
};