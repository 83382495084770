import React from 'react';
import { map } from 'lodash/fp';
import { array } from 'prop-types';
import classNames from 'classnames';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getI18N } from 'shared/hoc';
import Link from 'shared/components/link';
import s from './sub-header.scss';

export default compose(
  withStyles(s),
  setPropTypes({
    data: array.isRequired,
  }),
  getI18N,
  setDisplayName(__filename),
)(({ data, i18n }) => (
  <div className={s.Root}>
    <div className="container">
      <div className={s.Content}>
        {
          map(({ active, name, to }) => (
            <Link
              to={to}
              key={name}
              className={
                classNames('btn', 'btn-link', s.Link, { [s.Active]: active })
              }
            >
              {i18n(name)}
            </Link>
          ))(data)
        }
      </div>
    </div>
  </div>
));
