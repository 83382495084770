import React from 'react';
import { object } from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getI18N } from 'shared/hoc';
import Link from 'shared/components/link';
import Image from 'frontend/components/static-image';
import jump from 'jump.js';
import { HOME } from 'frontend/constants/features';
import s from './footer.scss';

export default compose(
  withStyles(s),
  getI18N,
  setPropTypes({
    currentAccount: object,
  }),
  setDisplayName(__filename),
)(({ i18n }) => (
  <div className={s.Root}>
    <div className="container">
      <div className={s.Navigator}>
        <div className="container d-flex justify-content-between">
          <div>
            <Link feature={HOME}>
              <Image src="/images/footer-logo.png" />
            </Link>
            <div
              className={s.Fee}
              onClick={() => jump('#advantage', 1000)}
              role="presentation"
            >
              {i18n('advantageSelection1')}
            </div>
          </div>
          <div className={s.NavigatorContainer}>
            <div className={s.NavigatorItem}>
              <h6>{i18n('service')}</h6>
              <Link className="btn btn-link" to="/article/customer-service">{i18n('customerService')}</Link>
            </div>
            <div className={s.NavigatorItem}>
              <h6>{i18n('contract')}</h6>
              <Link className="btn btn-link" to="/article/contract">{i18n('contractDescription')}</Link>
            </div>
            <div className={s.NavigatorItem}>
              <h6>{i18n('us')}</h6>
              <Link className="btn btn-link" to="/article/about-us">{i18n('aboutUs')}</Link>
            </div>
          </div>
        </div>
      </div>
      <div className={s.Copyright}>
        {i18n('copyright', {
          year: (new Date()).getFullYear(),
        })}
        {' '}
京ICP备18023534号
      </div>
    </div>
  </div>
));
