import { keys } from 'lodash/fp';

export const ADMIN = 'ADMIN';
export const CUSTOMER = 'CUSTOMER';
export const AGENCY = 'AGENCY';

const textMap = {
  [ADMIN]: 'admin',
  [CUSTOMER]: 'customer',
  [AGENCY]: 'agency',
};

export const roleTypes = keys(textMap);
