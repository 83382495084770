import React from 'react';
import { map, prop } from 'lodash/fp';
import { bool, func, array, string, any } from 'prop-types';
import { compose, setDisplayName, setPropTypes, withState, withHandlers } from 'recompose';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { getI18N } from 'shared/hoc';
import classNames from 'classnames';

export default compose(
  setPropTypes({
    isOpen: bool.isRequired,
    toggleIsOpen: func.isRequired,
    options: array.isRequired,
    onChange: func,
    buttonClass: string,
    fixedText: any,
    showAllOption: bool,
    right: bool,
    noCaret: bool,
    color: string,
  }),
  withState('selectedItem', 'setSelectedItem', ({ selectedItem }) => selectedItem),
  withHandlers({
    onSelectItem: ({ setSelectedItem, onChange }) => (item) => {
      setSelectedItem(item);
      onChange(prop('value', item));
    },
  }),
  getI18N,
  setDisplayName(__filename),
)(({
  isOpen,
  toggleIsOpen,
  options,
  onSelectItem,
  selectedItem,
  buttonClass,
  fixedText,
  i18n,
  showAllOption,
  right,
  noCaret,
  color,
}) => (
  <Dropdown isOpen={isOpen} toggle={toggleIsOpen}>
    <DropdownToggle
      caret={!noCaret}
      className={classNames('btn', buttonClass)}
      color={color}
    >
      {fixedText || prop('text')(selectedItem) || i18n('allOptions')}
    </DropdownToggle>
    <DropdownMenu right={right}>
      {showAllOption && (
        <DropdownItem
          onClick={() => {
            onSelectItem({
              text: i18n('allOptions'),
              value: '',
            });
          }}
        >
          {i18n('allOptions')}
        </DropdownItem>
      )}
      {map(({ value, text }) => (
        <DropdownItem
          key={value}
          disabled={selectedItem === value}
          onClick={() => { onSelectItem({ text, value }); }}
        >
          { text }
        </DropdownItem>
      ))(options)}
    </DropdownMenu>
  </Dropdown>
));
