import React from 'react';
import { object, string, func, bool } from 'prop-types';
import { prop, find, propEq, reject } from 'lodash/fp';
import classNames from 'classnames';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withState,
  withHandlers,
} from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  HOME,
  CONTRACT,
  EARNING,
  MY_WALLET,
  REGISTER,
  LOGIN,
  MY_WALLET_HISTORY,
  AGENCY_DASHBOARD,
  AGENCY_WALLET,
} from 'frontend/constants/features';
import Link from 'shared/components/link';
import { getI18N } from 'shared/hoc';
import { languageOptions, EN } from 'shared/constants/languages';
import { LANGUAGE } from 'shared/constants/cookies';
import Dropdown from 'frontend/components/fields/dropdown';
import {
  accountActionOptions,
  LOGOUT,
  ACCOUNT_SETTING,
} from 'shared/constants/account-actions';
import cookie from 'js-cookie';
import { AGENCY } from 'shared/constants/role-type';
import Image from 'frontend/components/static-image';
import s from './header.scss';

const { location } = global;

export default compose(
  withStyles(s),
  setPropTypes({
    currentAccount: object,
    currentFeature: string,
    updateAccount: func.isRequired,
    language: string.isRequired,
    logout: func.isRequired,
    push: func.isRequired,
    showLanguageDropdown: bool.isRequired,
  }),
  withState('isDropdownOpen', 'setIsDropdownOpen', false),
  withState('isAccountDropdownOpen', 'setIsAccountDropdownOpen', false),
  getI18N,
  withHandlers({
    toggleDropdown: ({
      isDropdownOpen,
      setIsDropdownOpen,
    }) => () => setIsDropdownOpen(!isDropdownOpen),
    toggleAccountDropdown: ({
      isAccountDropdownOpen,
      setIsAccountDropdownOpen,
    }) => () => setIsAccountDropdownOpen(!isAccountDropdownOpen),
    switchLanguage: ({ currentAccount, updateAccount }) => async (language) => {
      if (currentAccount) {
        await updateAccount({ id: currentAccount.id, language });
      }
      cookie.set(LANGUAGE, language, { expires: 60 });
      location.reload();
    },
    onAccountActionTaken: ({ logout, push }) => (action) => {
      switch (action) {
        case LOGOUT:
          logout();
          break;
        case ACCOUNT_SETTING:
        default:
          push('/account/setting');
          break;
      }
    },
  }),
  setDisplayName(__filename),
)(({
  currentAccount,
  currentFeature,
  isDropdownOpen,
  toggleDropdown,
  switchLanguage,
  isAccountDropdownOpen,
  toggleAccountDropdown,
  onAccountActionTaken,
  language,
  i18n,
  showLanguageDropdown,
}) => (
  <div className={classNames(s.Root, 'container')}>
    <div className={s.primary}>
      <Link feature={HOME}>
        <Image src="/images/logo.png" />
      </Link>

      <div className={s.Dropdowns}>
        {!currentAccount && (
          <div className={s.AuthAction}>
            <Link className="btn btn-outline-primary" feature={LOGIN} />
            <Link className="btn btn-primary" feature={REGISTER} />
          </div>
        )}
        {currentAccount && (
          <Dropdown
            buttonClass={s.AccountDropdown}
            isOpen={isAccountDropdownOpen}
            toggleIsOpen={toggleAccountDropdown}
            options={accountActionOptions(i18n)}
            fixedText={prop('username')(currentAccount)}
            onChange={onAccountActionTaken}
            color="primary"
          />
        )}

        {showLanguageDropdown && (
          <Dropdown
            buttonClass={s.LanguageDropdown}
            color="primary"
            isOpen={isDropdownOpen}
            toggleIsOpen={toggleDropdown}
            options={reject(propEq('value', EN))(languageOptions)}
            selectedItem={
              find(
                propEq('value', language),
              )(languageOptions)
            }
            onChange={switchLanguage}
          />
        )}
      </div>
    </div>

    {propEq('roleKey', AGENCY)(currentAccount) ? (
      <div className={s.Menu}>
        <div className={classNames(s.Link, {
          [s.ActiveLink]: currentFeature === AGENCY_DASHBOARD
          || currentFeature === AGENCY_WALLET,
        })}
        >
          <Link className="btn btn-link" feature={AGENCY_DASHBOARD}>
            {i18n('agency')}
          </Link>
        </div>
      </div>
    ) : (
      <div className={s.Menu}>
        <div className={classNames(s.Link, {
          [s.ActiveLink]: currentFeature === HOME,
        })}
        >
          <Link className="btn btn-link" feature={HOME} />
        </div>
        <div className={classNames(s.Link, {
          [s.ActiveLink]: currentFeature === CONTRACT || currentFeature === EARNING,
        })}
        >
          <Link className="btn btn-link" feature={CONTRACT}>
            {i18n('contract')}
          </Link>
        </div>
        <div className={classNames(s.Link, {
          [s.ActiveLink]: currentFeature === MY_WALLET || currentFeature === MY_WALLET_HISTORY,
        })}
        >
          <Link className="btn btn-link" feature={MY_WALLET}>
            {i18n('account')}
          </Link>
        </div>
      </div>
    )}
  </div>
));
